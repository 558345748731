<template>
  <div class="about">
    <div class="about_content">

        <h2>
          Artist
        </h2>
        Johanna Hartmann <br> 
        Concept, Design, Implementation, Direction
        <h2>
          About
        </h2>
        This homepage is the first term project of M.A. Creative Technologies Student Johanna Hartmann at Filmuniversity Babelsberg Konrad Wolf. In the following terms, more cases of algorithmic bias will be collected here.
        <h2>
          Technical Details
        </h2>
        This website uses Vue.js. The first case of the BAMF algorithm was realized with Javascript, Three.js and GSAP.
    </div>
  </div>
</template>

<style scoped lang="scss">

div{
  width: 60vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin:0 auto;

  h2{
    padding: 5%;
  }

}
</style>
